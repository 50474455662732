<template>
  <div class="home">
    <top></top>
    <div class="exchangecenter">
      <div class="nav-wrap">
        <div class="headline">
          <h2>{{ Replydata.title }}</h2>
          <img src="../../assets/home/red.png" alt="" v-if="startidear" @click="red" />
          <img src="../../assets/home/hui.png" v-else @click="hui" alt="" />
        </div>
        <!-- 人物信息 -->
        <div class="centr">
          <div class="message">
            <div>
              <img :src="Replydata.image"  v-image-preview   alt="" />
              <span>{{ Replydata.nickname }}</span>
            </div>
            <div>
              <div v-if="Replydata.disable">
                <span class="publicstate"> 已禁用</span>
                                <span
                  class="publicstate cause"
                 
                  @click="lookcause(Replydata.disable)"
                >
                  查看原因
                </span>
              </div>
              <div v-else>
                <span class="publicstate" v-show="Replydata.approvalstate == 0">
                  待审核</span
                >
                <span class="publicstate" v-show="Replydata.approvalstate == 1">
                  已通过</span
                >
                <span class="publicstate" v-show="Replydata.approvalstate == 2">
                  已拒绝
                </span>
                <span
                  class="publicstate cause"
                  v-show="Replydata.approvalstate == 2"
                  @click="lookcause(Replydata.disable)"
                >
                  查看原因
                </span>
                <el-button
                  type="danger"
                  v-show="Replydata.approvalstate == 2"
                  @click="editopen"
                  plain
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  v-show="Replydata.approvalstate == 2"
                  @click="delmessage"
                  plain
                  >删除</el-button
                >
              </div>
              <Goodsure
                :addDialog="addDialog"
                :title="title"
                @del="del"
                @addsure="addsure"
                :resultmessag="resultmessag"
              ></Goodsure>

              <Person
                :dialogVisibles="dialogVisibles"
                ref="mychild"
                @clear="clear"
                @suree="suree"
              ></Person>
            </div>
          </div>
          <p>
            {{ Replydata.describe }}
          </p>
        </div>
        <!-- 商品介绍 -->
        <!-- <div class="goodss">
          <h2>货物需求</h2>
          <div class="item">
            <div class="iamg">
              <img src="../../assets/index/item.png" alt="" />
              <span>待审核</span>
            </div>
            <div class="detial">
              <h3>三级抗震盘螺 HRB400E 10 玉昆</h3>
              <p><span>货物所在地</span>上海</p>
              <p><span>企业信息</span>上海</p>
            </div>
          </div>
        </div> -->
        <!-- 分类详情 -->
        <!-- <div class="description">
          <div class="left">
            <p><span>分类</span>热卷/低合金卷</p>
            <p><span>计价单价</span>吨</p>
            <p><span>是否预售</span>现货</p>
            <p><span>质量等级</span>正品</p>
            <p><span>规格</span>300*400*12</p>
            <p><span>详情描述</span>三级抗震盘螺 HRB400E 10 玉昆</p>
          </div>
          <div class="right">
            <p><span>涂层</span>无涂层</p>
            <p><span>成分</span>合金</p>
            <p><span>横截面</span>矩</p>
            <p><span>加工工艺</span>无缝化</p>
            <p><span>焊缝</span>直缝</p>
          </div>
        </div> -->

        <!-- 回复 -->
        <div class="apply" v-show="Replydata.approvalstate == 1">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="addReplyone.content"
          >
          </el-input>
         
          <el-button
            type="info"
            disabled
            v-if="!addReplyone.content"
            @click="addReply"
            >回复</el-button
          >

          <el-button type="danger" v-else @click="addReply">回复</el-button>
        </div>
        <!-- 全部评论 -->
        <div class="commente" v-if="this.replaydatas.length>0" v-show="Replydata.approvalstate == 1">
          <h2>全部评论 <span></span></h2>
          <div class="item" v-for="(item,index) in replaydatas" :key="index">
            <div class="cener">
              <span class="icon"></span>
              <img  v-image-preview  :src="item.image" alt="" />
              <div class="message">
                <h3>{{item.nickname}}</h3>
                <p>{{item.companyname}}</p>
              </div>
            </div>
            <p class="quest">{{item.content}}</p>
            <div class="data">
              <span>{{item.createtime}}</span>
              <img v-if="!item.replysecond" @click="replysecondcontent(item)" src="../../assets/home/xx.png" alt="" />
            </div>
             <div class="apply" v-show="!item.replysecond" v-if="replycontent==item.uuid">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="addReplytwo.content"
          >
          </el-input>
          
          <el-button
            type="info"
            disabled
            v-if="!addReplytwo.content"
            @click="addsecondReply(item)"
            >回复</el-button
          >
          <el-button type="danger" v-else @click="addsecondReply(item)">回复</el-button>
            <el-button
           type="primary"
          
           
            @click="unaddReply"
            >取消回复</el-button
          >
        </div>

            <div class="item two" v-if="item.replysecond">
              <div class="one" v-for="(second,index) in item.replysecond" :key="index">
                <div class="cener">
                  <span class="icon"></span>
                  <img  v-image-preview  :src="second.image" alt="" />
                  <div class="message">
                    <h3>{{second.nickname}}</h3>
                    <p>{{second.companyname}}</p>
                  </div>
                </div>
                <p class="quest">
                  回复 <span>{{item.nickname}}</span>：{{second.content}}
                </p>
                <div class="data">
                  <span>{{item.createTime}}</span>
                  <!-- <img src="../../assets/home/xx.png" alt="" /> -->
                </div>
              </div>
              <!-- <div class="three one">
                <div class="cener">
                  <span class="icon"></span>
                  <img src="../../assets/home/one.png" alt="" />
                  <div class="message">
                    <h3>许宝刚</h3>
                    <p>上海宝钢集团有限公司</p>
                  </div>
                </div>
                <p class="quest">
                  回复 <span>刘宝庆</span>：你好， 可以沟通一下吗？？你好，
                  可以沟通一下吗？？你好， 可以沟通一下吗？？你好，
                  可以沟通一下吗？？你好， 可以沟通一下吗？？你好，
                  可以沟通一下吗？？
                </p>
                <div class="data">
                  <span>2天前</span>
                  <img src="../../assets/home/xx.png" alt="" />
                </div>
              </div> -->
              <!-- <div class="more">更多俩条回复</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    
         <el-pagination
         class="el-pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="  prev, pager, next"
      :total="totalCount"
    
    >
    </el-pagination> -->

      <Pagination   v-show="Replydata.approvalstate == 1" class="el-pagination " ref="chanld" :num="totalCount" :limit="pageSize" :totalPageCount="totalPageCount"></Pagination>
      
    <Foo></Foo>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import goodsApi from "../../api/goodsApi";
import { Message, MessageBox } from "element-ui";
import Pagination from "@/components/prePage/pagination.vue";
import informationApi from "../../api/informationApi";
import Person from "../../components/center/entre/personlog.vue"
import Goodsure from "../../components/center/entre/cause.vue";
export default {
  data() {
    return {
   
      replied:false,
      dialogVisibles: false,
      resultmessag: [],
      addDialog: false,
      editform: {},
      title: "",
      // 信息广场获取信息详情
      Replydata: "",
      // 回复输入的内容
      textarea: "",
       pageNum:1,
      pageSize:3,
      totalCount:0,
      totalPageCount:0,
      replycontent:false,
      // 红星
       startidear: false,
      id: this.$route.query.id,
      addReplyone: {
        content: "",
        replyid: this.$route.query.id,
        secondid: this.$route.query.id,
      },
      addReplytwo: {
        content: "",
        replyid: "",
        secondid: "",
      },

      replaydatas:[],
    };
  },

  components: {
    // HelloWorld
    Top,
    Foo,
    Goodsure,
    Person,
    Pagination
  },
  mounted() {},
  mounted() {
    this.getInformationList();
    this.getReply();
    
  },
  methods: {
           change(even){
 this.pageNum = even
 this.getReply();
    },
          //  获取当前信息收藏状态
    getCollectRecordStateInformation() {
      informationApi.getCollectRecordStateInformation({ sourceId:this.id }, (res) => {
     
        if (res.data.code == 200) {
          this.startidear = res.data.result;
        }
      });
    },
        // 添加收藏
      hui() {
    
        informationApi.addCollectRecordInformation({sourceId: this.id }, (res) => {
          if (res.data.code == 200) {
            Message.success("收藏成功");
            this.startidear = true;
          } else {
            Message.error(res.data.message);
          }
       
        });
     
    }, 
      // 取消收藏
    red() {
     
      informationApi.delCollectRecordInformation({sourceId:this.id }, (res) => {
        if (res.data.code == 200) {
          Message.success("取消收藏");
          this.startidear = false;
        } else {
          Message.error(res.data.message);
        }
      });
    },
    // 取消回复
    unaddReply(){
       this.replycontent = false
    },
    // 回复二级内容
    replysecondcontent(item){
      this.replycontent = item.uuid
    },
    // 编辑
    editopen() {
      informationApi.getInformationInfo({ id: this.id }, (res) => {
     
        if (res.data.code == 200) {
          this.dialogVisibles = true;
            this.editform = res.data.result
            this.$refs.mychild.parentHandleclick(this.editform)
            this.$store.commit("initannounce", false);
        }
      });
    },
    // 删除信息
    delmessage() {
      // informationApi.delInformationSquare()
      MessageBox.confirm("确认删除商品吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          informationApi.delInformationSquare({ id: this.id }, (res) => {
          
            if (res.data.code != 200) {
              Message.error(res.data.message);
            } else {
              Message({
                message: "删除成功",
                type: "success",
              });
              this.$router.push("/home/collect");
            }
          });
        })
        .catch((_) => {
          Message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查看原因
    lookcause(disable) {
       if(disable){
            informationApi.getInformationDisableReason({id: this.id}, (res) => {
        
        
          if(res.data.code==200){
            this.addDialog = true; 
             this.title = "禁用原因";
          this.resultmessag = res.data.result;
          }
          else{
             Message.error(res.data.message);
          }
         
          
        });
      }
      else{
            informationApi.getInformationRefuseReason({id: this.id}, (res) => {
         
        
          if(res.data.code==200){
            this.addDialog = true; 
             this.title = "拒绝原因";
          this.resultmessag = res.data.result;
          }
          else{
             Message.error(res.data.message);
          }
         
          
        });
      }
    },
    // 查看原因
    del(bool) {
      this.addDialog = bool;
    },
    addsure(bool) {
      this.addDialog = bool;
    },
    clear(bool) {
      this.dialogVisibles = bool;
    },
    suree(bool) {
      this.dialogVisibles = bool;
    },
    // 信息广场添加回复
    addReply() {
      informationApi.addReply(this.addReplyone, (res) => {
      
        if (res.data.code == 200) {
          Message.success("发送成功") 
           this.addReplyone.content = "";
            this.getReply()
        } else {
            Message.error(res.data.message) 
        }
      });
    },
       // 信息广场添加二级回复
    addsecondReply(item) {
   
      this.addReplytwo.replyid = item.uuid
      this.addReplytwo.secondid = item.uuid
      informationApi.addSecondReply(this.addReplytwo, (res) => {
       
        if (res.data.code == 200) {
           this.addReplytwo.content = "";
           this.replied = true
             this.getReply() 
              Message.success("发送成功") 
        } else {
            Message.error(res.data.message) 
        }
      });
       this.replied = false
    },
    
    //  信息广场获取信息详情
    getInformationList() {
      informationApi.getInformationDetail({ id: this.id }, (res) => {
     
        if (res.data.code == 200) {
          this.Replydata = res.data.result;
          this.getCollectRecordStateInformation()
        }
      });
    },
 
    // 获取回复详情
    getReply() {
     
      informationApi.getReply({ informationId: this.id, page:this.pageNum,size:this.pageSize}, (res) => {
      
        if(res.data.code==200){
           this.replaydatas = res.data.result.data
           this.totalCount = res.data.result.totalCount
           this.totalPageCount = res.data.result.totalPageCount
           
             for (let index = 0; index < this.replaydatas.length; index++) {
            this.replaydatas[index].createTime = this.$moment(
              this.replaydatas[index].createTime
            ).format("yyyy/MM/DD HH:mm:ss")
             this.replaydatas[index].createtime = this.$moment(
              this.replaydatas[index].createtime
            ).format("yyyy/MM/DD HH:mm:ss")
          }
        }
      });
    },
       //监听pagesize改变事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
       this.getReply()

    },
  //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.pageNum = newPage;
       this.getReply()
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  min-width: 1100px;
}
.exchangecenter {
  width: 100%;
  // height: 1500px;
  min-height: 100vh;
  /* border: 1px solid red; */
}
.nav-wrap {
  //   border: 1px solid red;
  //   height: 600px;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  .headline {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  //人物信息
  .centr {
    //    border: 1px solid red;

    margin: 10px 0;
    .message {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: middle;
    }
    span {
      //    border: 1px solid red;
      font-size: 16px;
      margin-left: 10px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      color: rgb(51, 51, 51);
      line-height: 1.5;
    }
  }
  //商品介绍
  .goodss {
    //   border: 1px solid red;
    h2 {
      margin: 20px 0;
      border-left: 2px solid rgb(231, 80, 87);
      padding-left: 10px;
      color: rgb(231, 80, 87);
    }
    .item {
      border-bottom: 1px solid #ccc;
      padding-bottom: 20px;
      display: flex;
      .iamg {
        img {
          width: 130px;
          height: 130px;
          vertical-align: bottom;
          border-radius: 10px;
        }
        span {
          width: 130px;
          height: 20px;
          color: white;
          display: inline-block;
          text-align: center;
          margin-left: -130px;
          border-radius: 10px;
          // margin-top: -10px !important;
          background-color: rgba($color: #000000, $alpha: 0.3);
        }
      }

      .detial {
        margin-left: 20px;
        h3 {
          margin: 15px 0;
          font-size: 18px;
        }
        p {
          margin: 15px 0;
          font-size: 16px;
          span {
            font-size: 14px;
            color: rgb(105, 105, 105);
            margin-right: 20px;
          }
        }
      }
    }
  }
  //分类详情
  .description {
    //   border: 1px solid red;
    color: rgb(51, 51, 51);
    border-bottom: 1px solid #ccc;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    .left {
      p {
        margin: 10px 0;
        font-size: 16px;

        span {
          width: 100px;
          height: 20px;
          color: rgb(105, 105, 105);
          color: 14px;
          display: inline-block;
        }
      }
    }
    .right {
      flex: 0.6;
      p {
        margin: 10px 0;
        font-size: 16px;
        span {
          width: 100px;
          height: 20px;
          color: rgb(105, 105, 105);
          color: 14px;
          display: inline-block;
        }
      }
    }
  }
  //   回复
  .apply {
    margin: 20px 0;
    // border: 1px solid red;
    overflow: hidden;
    ::v-deep .el-textarea .el-textarea__inner {
      height: 100px !important;
      background-color: rgb(244, 243, 245);
    }
    .el-button {
      float: right;
      margin: 10px 10px;
      height: 30px !important;
      line-height: 5px;

      text-align: center;
      border: 0;
    }
  }
  //    全部评论
  .commente {
    // border: 1px solid red;

    h2 {
      margin: 20px 0;
      //   height: 80px;
      border-left: 2px solid rgb(231, 80, 87);

      padding-left: 10px;
      color: rgb(231, 80, 87);
      span {
        color: rgb(105, 105, 105);
        font-size: 14px;
        font-weight: normal;
      }
    }
    .item {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 10px 0;
      .cener {
        display: flex;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          vertical-align: middle;
        }
        .message {
          margin: 5px 10px;

          h3 {
            margin-bottom: 5px;
            font-size: 14px;
          }
          p {
            color: rgb(152, 149, 152);
          }
        }
      }
      .quest {
        margin: 10px;
        margin-left: 60px;
        color: rgb(105, 105, 105);
        font-size: 14px;
      }
      .data {
        display: flex;
        //   border: 1px solid red;
        justify-content: space-between;
        margin-left: 60px;
        span {
          color: rgb(105, 105, 105);
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .two {
      background-color: rgb(246, 243, 247);
      border: 0;
      margin: 20px 0;
      padding: 20px 20px;
      // border: 1px solid red;
      border-radius: 8px;
      .icon {
        width: 10px;
        height: 10px;
        display: inline-block;
        transform: rotate(45deg);
        background-color: rgb(246, 243, 247);
        // background-color: rgb(231, 80, 87);
        position: relative;
        top: -25px;
        left: 4px;
      }
      .one,
      .three {
        border-bottom: 1px solid rgb(231, 230, 230);
        // padding: 5px 0;
        padding-bottom: 10px;
        // padding-top: 5px;
      }
      .three {
        padding-top: 10px;
      }
      .more {
        // border: 1px solid red;
        width: 100px;
        padding-top: 10px;
        margin: 0px auto;
        color: rgb(105, 105, 105);
      }
    }
  }
}
.publicstate {
  color: rgb(233, 79, 85);
  margin-right: 20px;
  font-size: 14px !important;
}
.cause {
  cursor: pointer;
  color: #409eff;
}
// .pagination{
//     margin-top: 20px;
//     border: 1px solid red;
//     // width: 100%;
//     // margin: 0 auto;
//     .el-pagination{
//        border: 1px solid red;
//        width: 50%;
//        margin: 20px auto;
     
//     }
// }
.el-pagination{
    // border: 1px solid red;
    width: 50%;
    margin: 20px auto;
    // margin-top: 20px;
}
</style>
